.main {
    .menu {
        width: 100%;

        z-index: 2;
        position: fixed;
        top: 0;

        pointer-events: none;
        user-select: none;

        .menu-logo {
            position: absolute;

            max-width: 160px;
            width: 100%;

            left: 30px;
            top: 25px;

            cursor: pointer;

            .menu-logo-top {
                // margin-bottom: 6px;

                img {
                    width: 165px;
                    pointer-events: all;
                }
            }

            .menu-logo-bottom {
                font-size: 13px;
                font-weight: 700;
                text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);

                color: #ffffff;

                pointer-events: all;
            }
        }

        .menu-burger {
            display: none;

            position: absolute;
            pointer-events: all;

            right: 30px;
            top: 25px;

            cursor: pointer;

            img {
                width: 35px;
                transition: 0.2s;
            }
        }

        .menu-group {
            transition: 0.3s;
            pointer-events: all;
        }

        .menu-blocks {
            position: absolute;

            left: 30px;
            top: 125px;

            display: flex;
            justify-content: space-between;
            flex-direction: column;

            gap: 10px;

            @media screen and (min-width: 500px) {
                max-height: calc(100vh - 125px);
                overflow-y: auto;
                overflow-x: hidden;
            }

            .menu-block {
                width: 210px;
                display: block;

                transition: 0.2s;

                // .menu-block-button {
                //     width: 100%;
                //     height: 43px;

                //     // background-color: rgb(255, 255, 255);
                //     // border-radius: 15px;

                //     border-radius: 8px;
                //     border: 1px solid #e0dee3;
                //     background: rgba(0, 0, 0, 0.1);
                //     backdrop-filter: blur(5px);

                //     display: flex;
                //     align-items: center;
                //     justify-content: center;

                //     transition: 0.2s;

                //     cursor: pointer;
                //     position: relative;

                //     pointer-events: all;

                //     .menu-block-button-inner {
                //         width: 85%;

                //         display: flex;
                //         align-items: center;
                //         justify-content: space-between;

                //         .menu-block-button-text {
                //             font-size: 12px;
                //             text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

                //             color: #ffffff;

                //             b {
                //                 color: #ffffff;
                //             }
                //         }

                //         img {
                //             width: 12px;
                //             height: 10px;

                //             transition: 0.2s;
                //         }
                //     }
                // }

                .menu-block-list {
                    z-index: 1;

                    width: 100%;

                    // background-color: rgb(255, 255, 255);
                    // border-radius: 15px;

                    border-radius: 8px;
                    border: 1px solid #e0dee3;
                    box-sizing: border-box;
                    background: rgba(0, 0, 0, 0.1);
                    backdrop-filter: blur(5px);

                    transition: 0.2s ease-out;

                    pointer-events: all;

                    .menu-block-list-title {
                        width: 85%;
                        height: 35px;

                        margin: 0 auto;

                        font-size: 12px;

                        padding-top: 14px;
                        box-sizing: border-box;

                        // color: #000000;
                        color: #ffffff;
                    }

                    .menu-block-list-element {
                        width: 100%;
                        height: 33px;

                        font-size: 13px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        cursor: pointer;
                        transition: 0.2s;

                        // color: #000000;
                        color: #ffffff;

                        .menu-block-list-element-inner {
                            width: 85%;
                            display: flex;
                            align-items: center;

                            .menu-block-list-radio-button {
                                width: 15px;
                                height: 15px;

                                // border: 1px solid rgb(63, 63, 63);
                                border: 1px solid rgb(255, 255, 255);
                                border-radius: 100px;
                                box-sizing: border-box;

                                margin-right: 12px;

                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .menu-block-list-radio-button-inner {
                                    width: 9px;
                                    height: 9px;

                                    // background-color: #a15652;
                                    background-color: #ffffff;
                                    border-radius: 100px;

                                    transition: 0.2s;
                                }
                            }

                            .menu-block-list-checkbox-button {
                                width: 15px;
                                height: 15px;

                                // border: 1px solid rgb(63, 63, 63);
                                border: 1px solid rgb(255, 255, 255);
                                border-radius: 1px;
                                box-sizing: border-box;

                                margin-right: 12px;

                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .menu-block-list-checkbox-button-inner {
                                    width: 9px;
                                    height: 9px;

                                    // background-color: #a15652;
                                    background-color: #ffffff;
                                    border-radius: 1px;

                                    transition: 0.2s;
                                }
                            }
                        }

                        &:last-child {
                            // border-radius: 0 0 15px 15px;
                            border-radius: 0 0 8px 8px;
                        }
                    }
                }
            }
        }
        .menu-right-switchbox {
            position: absolute;

            right: 158px;
            top: 75px;

            display: flex;
            // align-items: left;
            flex-direction: column;
            justify-content: space-between;
            gap: 5px;
        }

        .menu-switch-new-projects {
            position: absolute;
            left: 30px;
            top: 90px;
        }

        .menu-switch {
            display: flex;
            align-items: center;
            justify-content: center;

            pointer-events: all;

            height: 24px;

            .menu-switch-inner {
                width: 100%;

                display: flex;
                align-items: center;

                cursor: pointer;

                .menu-switch-element {
                    margin-right: 15px;
                }

                .menu-switch-label {
                    font-size: 13px;
                    color: #ffffff;
                }
            }
        }

        .menu-button {
            position: absolute;

            right: 125px;
            top: 25px;

            max-width: 172px;
            width: 100%;
            height: 36px;

            backdrop-filter: blur(10px);
            box-shadow: inset 0 0 10px 5px rgba(255, 255, 255, 0.02);
            border: 1px solid #e6e2e2e5;
            border-radius: 8px;

            transition: 0.2s;

            cursor: pointer;
            pointer-events: all;

            display: flex;
            align-items: center;
            justify-content: center;
            gap: 14px;

            font-size: 12px;
            color: #ffffff;

            img {
                width: 16px;

                transition: 0.3s;
            }
        }

        .menu-language {
            position: absolute;
            right: 10px;
            top: 25px;

            display: flex;
            flex-direction: column;
            gap: 10px;

            .menu-language-button {
                height: 30px;
                width: 62px;

                border-radius: 5px;
                border: 1px solid rgba(224, 222, 227, 1);

                display: flex;
                align-items: center;
                justify-content: center;

                cursor: pointer;
                pointer-events: all;

                .menu-language-inner {
                    max-width: 45px;
                    width: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .menu-language-image {
                        width: 14px;
                        height: 14px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .menu-language-label {
                        font-size: 14px;
                        font-weight: 700;

                        color: #ffffff;
                    }
                }
            }
        }
    }
}
