.main {
    .menu {
        .language-dropdown {
            position: absolute;
            z-index: 10;

            top: 25px;
            right: 29px;

            pointer-events: all;

            &::content {
                pointer-events: all;
            }
        }
    }
}
