.main {
    @media screen and (max-width: 1300px) {
        .header {
            .header-container {
                max-width: 90% !important;
                margin-top: 30px !important;

                align-items: baseline !important;

                .header-right {
                    display: block !important;
                    max-width: calc(
                        170px + 170 * ((100vw - 320px) / 1600)
                    ) !important;

                    .header-dropdown {
                        max-width: calc(
                            170px + 170 * ((100vw - 320px) / 1600)
                        ) !important;

                        pointer-events: none !important;

                        .header-dropdown-button {
                            pointer-events: all !important;
                        }

                        .header-dropdown-list {
                            max-width: calc(
                                170px + 170 * ((100vw - 320px) / 1600)
                            ) !important;

                            pointer-events: all !important;
                        }
                    }

                    .header-button {
                        max-width: calc(
                            170px + 170 * ((100vw - 320px) / 1600)
                        ) !important;
                    }

                    .header-right-switchbox {
                        position: fixed;
                        left: 5%;
                        top: 100px;

                        max-width: calc(135px + 130 * ((100vw - 320px) / 1600));
                    }

                    .header-switch-element {
                        margin-right: calc(
                            10px + 10 * ((100vw - 320px) / 1600)
                        ) !important;
                    }

                    // .header-switch {
                    //     max-width: calc(170px + 170 * ((100vw - 320px) / 1600)) !important;
                    //     height: 43px !important;

                    //     bottom: 0 !important;
                    //     position: fixed;

                    //     // background-color: #FFFFFF;
                    //     // border-radius: 15px 15px 0 0;

                    //     background-color: rgba(197, 197, 197, 0.153);
                    //     backdrop-filter: blur(10px);
                    //     box-shadow: inset 0 0 10px 5px rgba(255, 255, 255, 0.02);
                    //     border: 1px solid #e6e2e2e5;
                    //     border-radius: 8px 8px 0 0;

                    //     .header-switch-inner {
                    //         max-width: 90% !important;

                    //         .header-switch-label {
                    //             // color: #000000 !important;
                    //         }
                    //     }
                    // }
                }
            }
        }

        .card {
            .card-container {
                max-height: 96% !important;
                overflow-y: auto;
            }
        }
    }

    @media screen and (max-width: 500px) {
        .card {
            justify-content: center;

            .card-container {
                max-width: 92% !important;
                right: auto !important;

                .card-bottom {
                    .card-title {
                        font-size: calc(
                            17px + 17 * ((100vw - 320px) / 1600)
                        ) !important;
                    }

                    .card-desc {
                        font-size: calc(
                            12px + 13 * ((100vw - 320px) / 1600)
                        ) !important;
                    }

                    .card-bottom-button-inner {
                        font-size: calc(
                            12px + 13 * ((100vw - 320px) / 1600)
                        ) !important;
                    }
                }
            }
        }

        .menu {
            .menu-logo {
                .menu-logo-top {
                    // margin-bottom: 6px;

                    img {
                        width: 145px !important;
                    }
                }
            }

            .menu-burger {
                display: block !important;
            }

            .menu-button {
                right: auto !important;
                left: 14px !important;
                top: calc(100dvh - 36px - 18px) !important;
            }

            .menu-group {
                // display: none;
                position: absolute;
                left: 50%;
                top: 80px;

                border-radius: 8px;
                background: rgba(0, 0, 0, 0.2);
                backdrop-filter: blur(5px);

                height: 450px !important;
                width: calc(100vw - 16px);

                padding: 5px;
                box-sizing: border-box;

                .menu-blocks {
                    left: 0 !important;
                    top: 0 !important;

                    display: block !important;

                    .menu-block {
                        position: absolute !important;
                        width: calc(50vw - 8px - 10px - 5px);

                        .menu-block-list-title {
                            font-size: 3.1vw !important;
                        }

                        .menu-block-list-element {
                            font-size: 3.1vw !important;
                        }
                    }

                    .menu-block-developments {
                        top: 55px !important;
                        left: 10px !important;
                    }

                    .menu-block-project-type {
                        top: 168px !important;
                        left: 10px !important;
                    }

                    .menu-block-infrastructure {
                        top: 135px !important;
                        left: calc(50vw - 8px + 5px) !important;
                    }
                }

                .menu-right-switchbox {
                    right: 10px !important;
                    top: 60px !important;

                    width: calc(50vw - 8px - 10px - 15px) !important;
                }

                .menu-switch-label {
                    font-size: 3.1vw !important;
                }

                .menu-switch-new-projects {
                    position: relative !important;
                    left: 10px !important;
                    top: 18px !important;
                }

                .language-dropdown {
                    right: 12px !important;
                    top: 17px !important;
                }
            }
        }

        .bottom-tips {
            .bottom-tips-qr {
                right: 11px !important;
                bottom: 18px !important;

                img {
                    width: 75px !important;
                    height: 75px !important;
                }
            }

            .bottom-tips-maptype {
                top: 85px !important;
                left: 30px !important;
            }
        }

        .window-card {
            .window-card-container {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}
